<template>
<div class="convention-hall page">
  <!-- <van-nav-bar
      class="nav-bar"
      title="Trò chơi"
  /> -->
  <div class="convention-items">
    <div>
      <van-sidebar class="convention-item" @change="onChange" v-model="activeKey">
        <van-sidebar-item :title="this.$t('all')" />
        <van-sidebar-item v-for="(v,key) in lotteryitem" :key="key" :title="v.name" />
      </van-sidebar>
    </div>
    <div class="hot-items-div">
      <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
        <van-grid :column-num="2">
          <van-grid-item class="game_item" @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
            <van-image class="game_item_img" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
            <p>{{v.name}}</p>
            <!-- <span>{{v.desc}}</span> -->
          </van-grid-item>
        </van-grid>
      </van-pull-refresh>
    </div>
  </div>
</div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      gameitem: [{},{},{},{}],
      lotteryitem: [{},{},{},{}],
      isLoading: false,
      activeKey: 0,
    };
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        Toast(this.$t('refresh_success'));
        this.isLoading = false;
      }, 500);
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res=>{
        this.gameitem = res.data;
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data:{class:index},
        url: 'lottery_list'
      }).then(res=>{
        this.gameitem = res.data;
      })
    },
    getLotteryItem(){
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res=>{
        this.lotteryitem = res.data;
      })
    }
  },
  created() {
    this.getGameItem();//获取首页游戏列表
    this.getLotteryItem();
  }
};
</script>

<style lang='less' scoped>
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar{
  background: linear-gradient(
      90deg,#7e5678,#e6c3a1);
  height: 50px;
}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 80%;
    margin: 0 auto;
    color: #fff;
    font-size: 18px;
}
::v-deep .van-nav-bar__content {
  height: 50px;
}
.van-sidebar {
  width: 100%;
}

.van-sidebar-item--select {
  background: linear-gradient(to bottom right,#fae64f,#f79883);
}
.van-sidebar-item{
  font-size: 13px;
  text-align: center;
  padding: 5px;
  background: linear-gradient(to bottom right, #8da5ce, #46c0f9);
  width: 100%;
  color: #fff;
  margin: 1px;
  height: 43.6px;
  line-height: 35px;
}
.van-sidebar-item__text {
    line-height: 1.2;
    word-break: normal;
}
.van-sidebar-item--select, .van-sidebar-item--select:active {
  background: linear-gradient(to bottom right, #fae64f, #f79883);
}
.convention-item{
  display: flex;
  align-items: center;
  padding: 2px;
  width: calc(100% - 4px);

}
.convention-hall{
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #bac3d2;
}
.convention-item .left{
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  width: 27%;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right{
  height: 100%;
  flex: 1;
  background-color: #f2f2f5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  min-height: calc(100vh - 50px);
}
.convention-item .right .list-wrapper{
  padding: 5px;
}
.convention-item .right .list-wrapper .game_item_img{
  width: 100px;
  height: 100px;
}
.convention-item .right .list-wrapper span{
  margin-top: 10px;
  font-size: 15.5px;
  color: #000;
}
.convention-item .right .list-wrapper span:last-child{
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}
.van-grid-item {
  padding: 5px;

}
.game_item{
  padding: 5px;
  max-width: 50%;
}
.game_item>div {
    padding: 10px 10px 5px;
    background: #ebedec;
    border-radius: 5px;
    box-shadow: 0 2px 3px -1px rgba(255, 87, 34, .5);
    overflow: hidden;
}
.hot-items-div .game_item_img{
    width: 180px;
    height: 100px;
}
::v-deep .van-pull-refresh__track{
  transform: none !important;
}
::v-deep .van-grid-item__content--center {
  border-radius: 15px;
}
::v-deep .van-image__img{
  border-radius: 3px;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}
.game_item p{
  margin: 8px 0 5px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: #ef3340;
    width: 140px;
    padding: 5px;
    text-align: center;
}

</style>
