<template>
    <div class="container page">
      <div class="header">
        <van-nav-bar :title="$t('language')" class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()"/>
          </template>
        </van-nav-bar>
      </div>
      <div class="content">
        <div class="wrap_box" @click="changeLanguage('vi')">
          <div class="item">
            <div class="left flex_center">
                <div>
                    <img src="/img/language/vi_vn.png" alt="">
                </div>
                <div class="info">Tiếng Việt</div>
            </div>
            <div v-if="$i18n.locale === 'vi'">✓</div>
          </div>
        </div>
        <div class="wrap_box" @click="changeLanguage('en')">
          <div class="item">
            <div class="left flex_center">
                <div>
                    <img src="/img/language/en_us.png" alt="">
                </div>
                <div class="info">English</div>
            </div>
            <div v-if="$i18n.locale === 'en'">✓</div>
          </div>
        </div>
        <div class="wrap_box" @click="changeLanguage('th')">
          <div class="item">
            <div class="left flex_center">
                <div>
                    <img src="/img/language/th_th.png" alt="">
                </div>
                <div class="info">ภาษาไทย</div>
            </div>
            <div v-if="$i18n.locale === 'th'">✓</div>
          </div>
        </div>
        <div class="wrap_box" @click="changeLanguage('jp')">
          <div class="item">
            <div class="left flex_center">
                <div>
                    <img src="/img/language/ja_jp.png" alt="">
                </div>
                <div class="info">日本語</div>
            </div>
            <div v-if="$i18n.locale === 'jp'">✓</div>
          </div>
        </div>
        <div class="wrap_box" @click="changeLanguage('ko')">
          <div class="item">
            <div class="left flex_center">
                <div>
                    <img src="/img/language/ko_kr.png" alt="">
                </div>
                <div class="info">한국어</div>
            </div>
            <div v-if="$i18n.locale === 'ko'">✓</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        is_bind:false,
        bankInfo:{},
        userInfo:{}
      };
    },
    methods: {
      back(){
        return window.history.back();
      },
      changeLanguage(lang) {
        this.$i18n.locale = lang
        localStorage.setItem('language', lang);
        this.$emit('language-changed', lang);
        window.location.reload();
      }
    },
    created() {
      const savedLanguage = localStorage.getItem('language');
      if (savedLanguage) {
        this.$i18n.locale = savedLanguage;
      }
    }
  };
  </script>
  
  
  <style lang='less' scoped>
  @import "../../assets/css/base.css";
  .van-cell {
    font-size: 18px;
    line-height: 40;
  }
  .page{
    background: transparent;
  }
  .nav-bar {
      background: #ef3340;
  }
  .wrap_box {
    border-bottom: 1px solid #cfcfcf;
    padding: 8px 20px;
    font-size: 14px;
  }
  .wrap_box>.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrap_box>.item>.flex_center {
    display: flex;
    align-items: center;
  }
  .wrap_box>.item>.flex_center img{
    height: 30px;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
  .wrap_box>.item>div:not(.flex_center) {
    color: green;
    font-weight: 700;
    font-size: 20px;
  }
  
  .container p{
    padding: 0 15px;
    margin-top: 15px;
    font-size: 16px;
    color: #dc2037;
  }
  .manage-card .wrapper{
    height: calc(100% - 10px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .wrapper .add-card{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: #fff;
    height: 150px;
  }
  .wrapper .add-card span{
    margin-left: 10px;
    font-size: 18px;
  }
  .wrapper .tips {
    margin: 15px 15px;
    font-size: 18px;
    color: #979799;
  }
  .wrapper .bank .info {
    margin-left: 20px;
    flex: 1;
    color: #000;
  }
  .wrapper .bank .info .row-content{
    margin: 30px 0;
    line-height: 20px;
    font-size: 30px;
  }
  .box-recharge{
    padding: 15px;
  }
  .box-recharge div{
      text-align: center;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 10px;
      margin-bottom: 15px;  
  }
  .box-recharge img {
      max-width: 40px;
      border-radius: 10px;
      margin-right: 20px;
  }
  .box-recharge i {
      margin-left: auto;
      font-size: 18px;
  }
  </style>
  