import Vue from 'vue'
import VueI18n from 'vue-i18n'
import viMessages from '../locales/vi.json'
import enMessages from '../locales/en.json'
import thMessages from '../locales/th.json'
import jpMessages from '../locales/jp.json'
import koMessages from '../locales/ko.json'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'vi', // set locale
  fallbackLocale: 'vi', // set fallback locale
  messages: {
    vi: viMessages,
    en: enMessages,
    th: thMessages,
    jp: jpMessages,
    ko: koMessages
  }
})

export default i18n