<template>
  <div class="container page">
    <van-nav-bar :title="this.$t('setting')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="items">
      <div class="item van-hairline--bottom" @click="toInfomation()">
        <div class="left">{{ this.$t('setting_information') }}</div>
        <van-icon name="arrow" />
      </div>
      <div class="item van-hairline--bottom" @click="toLoginPassword()">
        <div class="left">{{ this.$t('change_password') }}</div>
        <van-icon name="arrow" />
      </div>
      <!-- <div class="item van-hairline--bottom" @click="toPayPassword()">
        <div class="left">Mật Khẩu Thanh Toán</div>
        <div class="right">
          <span class="desc">{{ this.userInfo.paypassword }}</span>
          <van-icon name="arrow" />
        </div>
      </div> -->
    </div>
    <van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{ this.$t('sign_out') }}</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {}
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    toPayPassword() {
      if (this.userInfo.paypassword !== "Chưa Cài Đặt") {
        this.$toast("Mật khẩu rút tiền đã được cài đặt, nếu muốn thay đổi vui lòng liên hệ bộ phận chăm sóc khách hàng");
      } else {
        this.$router.push({ path: '/SetPayPassword' });
      }
    },
    toLoginPassword() {
      this.$router.push({ path: '/SetLoginPassword' });
    },
    toInfomation() {
      this.$router.push({ path: '/Infomation' });
    },
    loginout() {
      localStorage.clear();
      this.$router.push({ path: '/Mine' });
    },
    toServicePage() {
      this.$router.push("ServicePage");
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          if (res.data.paypassword) {
            this.userInfo.paypassword = "Đã Cài Đặt";
          } else {
            this.userInfo.paypassword = "Chưa Cài Đặt";
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' });
    } else {
      this.getUserInfo();
    }
  }
};
</script>


<style lang='less' scoped>
@import "../../assets/css/base.css";
.page{
  background: transparent;
}
.nav-bar {
  background: #ef3340;
}
.container .items {
  background-color: #fff;
  font-size: 16px;
  color: #000;
  padding: 0 20px;
}
.page{
  height: 100vh;
}

.container .items .item {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 10px;
}

.container .items .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.container .sign-out {
  margin: 20px auto;
  max-width: 400px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border: none;
  background: linear-gradient(90deg, #fe8f30, #ff3384);
  display: block;
}

.container .item .desc {
  font-size: 16px;
  font-weight: 700;
  color: #979799;
}

.container .item .right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
