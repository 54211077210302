<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="this.$t('recharge')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="">
      <div class="box-recharge">
        <div>
          <img src="/img/mine/hana.jpg" alt="">
          <div>HANA BANK</div>
          <i class="van-icon van-icon-arrow van-cell__right-icon"></i>
        </div>
        <div>
          <img src="/img/mine/ibk.webp" alt="">
          <div>IBK BANK</div>
          <i class="van-icon van-icon-arrow van-cell__right-icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_bind:false,
      bankInfo:{},
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
            this.bankInfo = res.data.info;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    toBindCard() {
      if (!this.userInfo.name) {
        this.$router.push("Setname");
        this.$toast("Vui lòng đặt tên trước khi liên kết thẻ ngân hàng!");
        return true;
      }else if(!this.userInfo.paypassword){
        this.$router.push("SetPayPassword");
        this.$toast("Vui lòng đặt mật khẩu rút tiền trước khi liên kết thẻ ngân hàng!");
        return true;
      } else {
        this.$router.push({path:'/BindCard'})
      }
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>


<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 18px;
  line-height: 40;
}
.page{
  background: transparent;
}
.nav-bar {
    background: #ef3340;
}

.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 16px;
  color: #dc2037;
}
.manage-card .wrapper{
  height: calc(100% - 10px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.wrapper .add-card{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  height: 150px;
}
.wrapper .add-card span{
  margin-left: 10px;
  font-size: 18px;
}
.wrapper .tips {
  margin: 15px 15px;
  font-size: 18px;
  color: #979799;
}
.wrapper .bank .info {
  margin-left: 20px;
  flex: 1;
  color: #000;
}
.wrapper .bank .info .row-content{
  margin: 30px 0;
  line-height: 20px;
  font-size: 30px;
}
.box-recharge{
  padding: 15px;
}
.box-recharge div{
    text-align: center;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;  
}
.box-recharge img {
    max-width: 40px;
    border-radius: 10px;
    margin-right: 20px;
}
.box-recharge i {
    margin-left: auto;
    font-size: 18px;
}
</style>
