<template>
  <div class="mine page">
    <div class="page-bg"></div>
    <div class="wrapper">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="header">
          <div class="cskh">
            <img src="img/mine/service.png" alt="">
          </div>
          <van-nav-bar class="nav-bar">
            <template #right>
              <van-icon name="setting-o" @click="showSetting()" color="#fff" />
            </template>
          </van-nav-bar>
          <div class="user-wrapper" @click="doLogin()">
            <van-image round class="user_img" :src="this.userInfo.header_img">
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <div class="login-content">
              <p class="login-btn">ID: {{ this.userInfo.id }}</p>
              <!-- <p class="login-label">{{ this.userInfo.ip }}</p> -->
              <p class="login-label">
                <img data-v-2230b3a8="" src="img/mine/level_vip.webp" width="40"
                  style="position: relative; top: 10px; margin-right: 2px;">
                VIP {{ this.userInfo.vip }}
              </p>
            </div>
          </div>
          <div class="account-money">
            <div class="accounts u-flex u-row-between">
              <p>{{ this.$t('star_num') }}</p>
              <p class="accounts-money">{{ this.userInfo.money }}</p>
            </div>
            <div class="u-flex u-row-around deposit">
              <button @click="doWithdrawal()" class="van-button van-button--default van-button--small van-button--round" style="margin-right: 10px; color: rgb(255, 255, 255); background: rgb(239, 51, 64); border: 0px; font-size: 16px;">{{ this.$t('withdraw') }}</button>
            </div>
          </div>
          <div class="money_zz"></div>
        </div>
        <div class="content">
          <div class="box-mine">
            <div class="item-box-mine" @click="toWithdrawalHistory()">
              <img src="img/mine/icon4.png" alt="">
              <span>{{ this.$t('withdrawal_history') }}</span>
            </div>
            <div class="item-box-mine" @click="$router.push({ path: '/GameRecord' });">
              <img src="img/mine/icon2.png" alt="">
              <span>{{ this.$t('order_history') }}</span>
            </div>
            <div class="item-box-mine" @click="$router.push({ path: '/Infomation' });">
              <img src="img/mine/icon4.png" alt="">
              <span>{{ this.$t('personal_information') }}</span>
            </div>
            <div class="item-box-mine" @click="$router.push({ path: '/SetLoginPassword' });">
              <img src="img/mine/icon7.png" alt="">
              <span>{{ this.$t('login_password') }}</span>
            </div>
            <div class="item-box-mine" @click="$router.push({ path: '/Setbank' });">
              <img src="img/mine/icon8.png" alt="">
              <span>{{ this.$t('link_bank') }}</span>
            </div>
            <div class="item-box-mine" @click="doPay()">
              <img src="img/mine/icon9.png" alt="">
              <span>{{ this.$t('recharge') }}</span>
            </div>
          </div>
          
          <!-- <div class="finance">
            <div class="finance-item" @click="doPay()">
              <van-icon class="icon" style="" name="peer-pay" />
              <span class="text">Nạp tiền</span>
            </div>
            <div class="line"></div>
            <div class="finance-item" @click="doWithdrawal()">
              <van-icon class="icon" name="idcard" />
              <span class="text">Rút tiền</span>
            </div>
          </div> -->
          <!-- <div v-if="this.userInfo.money" class="wallet">
            <div class="part-1 van-hairline--bottom">
              <p class="flex-1 font-20 font-primary-color">Ví của tôi</p>
              <span class="font-15 font-gray">Chi tiết</span>
              <van-icon class="font-gray" style="font-size: 28px" name="arrow" />
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{ formatPrice(this.userInfo.money) }}</p>
              <span class="font-18 font-gray">Số dư</span>
              <div class="refresh-btn" @click="refresh()">
                <van-icon name="replay" />
              </div>
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{ this.userInfo.credit }}</p>
              <span class="font-18 font-gray">Điểm tín nhiệm</span>
              <div class="refresh-btn" @click="refresh()">
                <van-icon name="replay" />
              </div>
            </div>
          </div> -->
          <!-- <div :style="{ marginTop: menu_top + 'px' }" class="menu">
            <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
              <van-image class="menu-item-icon" src="img/mine/baobiao.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Báo cáo cá nhân</span>
            </div>
            <div class="menu-item" @click="exit()">
              <van-image class="menu-item-icon" src="img/mine/mingxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Chi tiết tài khoản</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/GameRecord' });">
              <van-image class="menu-item-icon" src="img/mine/youxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Lịch sử đánh giá</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/Infomation' });">
              <van-image class="menu-item-icon" src="img/mine/user.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Trung tâm cá nhân</span>
            </div>
            <div class="menu-item" @click="toNotice()">
              <van-image class="menu-item-icon" src="img/mine/gonggao.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Thông báo</span>
            </div>
            <div class="menu-item" @click="toService()">
              <van-image class="menu-item-icon" src="img/mine/kefu_1.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Chăm sóc khách hàng</span>
            </div>
          </div> -->
        </div>
      </van-pull-refresh>
      <van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{ this.$t('sign_out') }}</van-button>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      menu_top: 40,
      isLoading: false,
    };
  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.$toast("Làm mới thành công");
        } else {
          this.$router.push({ path: '/Login' });
        }
      }, 500);
    },
    exit() {
      this.$toast("Vui lòng hoàn thành đơn nhiệm vụ trước khi vào");
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Setting' });
      } else {
        this.$router.push({ path: '/Login' });
      }
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' });
      } else {
        this.$router.push({ path: '/Login' });
      }
    },
    toWithdrawalHistory() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/WithdrawRecord' });
      } else {
        this.$router.push({ path: '/Login' });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.getUserInfo();
          this.$toast("Làm mới thành công");
        } else {
          this.$router.push({ path: '/Login' });
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' });
      } else {
        this.$router.push({ path: '/Login' });
      }
    },
    doPay() {
      this.$router.push({ path: '/ServiceOnline' });
      // this.$toast("Vui lòng liên hệ dịch vụ khách hàng để nạp tiền");
      // return false;
    },
    doWithdrawal() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank");
          this.$toast.fail("Vui lòng thiết lập thẻ nhận tiền!");
        }
      });
    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail("Chức năng đã bị vô hiệu hóa!");
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast("Tài khoản đã bị đăng xuất");
            localStorage.clear();
            this.$router.push({ path: '/Login' });
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    loginout() {
      localStorage.clear();
      this.$router.push({ path: '/Login' });
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
    } else {
      this.userInfo.username = "Đăng nhập/Đăng ký";
      this.userInfo.ip = "Đăng nhập để trải nghiệm thêm dịch vụ!";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style scoped>
.page {
  height: 100vh;
}

.mine {
  position: relative;
  bottom: 10px;
  background: linear-gradient(1turn, #fff, #ef3340);
}

.mine .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.van-nav-bar__right {
  top: 10px;
}

.nav-bar {
  background: transparent;
}

.mine .header {
  background: linear-gradient(rgb(239 51 64 / 90%), rgb(239 51 64 / 30%)), url(/img/mine/user_head.png) no-repeat 50% / cover;
  position: relative;
}

::v-deep .van-nav-bar__content {
  height: 40px;
}

::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
  font-size: 30px;
  margin-top: 10px;
}

.mine .header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0 25px 0 25px;

}

.mine .user_img {
  height: 40px;
  width: 40px;
}

::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}

::v-deep .van-image__error-icon {
  font-size: 70px;
}

.mine .header .user-wrapper .login-content {
  flex: 1;
  margin-left: 15px;
}
.van-button--small{
  padding:6px 15px;
}

.mine .header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 20px;
  line-height: 0;
  font-weight: 600;
  color: #333;
}

.mine .header .user-wrapper .login-content .login-label {
  margin-top: 0;
  font-size: 22px;
  display: inline-block;
  margin-left: 20px;
  color: rgb(200, 157, 96);
  font-weight: bold;
}

.mine .page-bg {
  height: 100vh;
  background: #fff;
}

.mine .content {
  position: relative;
  padding: 10px 0;
  height: auto;
  background-color: #fff;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -28px;
  left: 20px;
  right: 20px;
  height: 65px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
  width: 3px;
  height: 40px;
  background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
  margin-left: 10px;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}

.mine .wrapper .content .finance .finance-item .icon {
  font-size: 20px;
}

.mine .wrapper .content .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 70px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
  font-size: 14px;
  color: #868686;
  font-weight: 500;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
  margin: 15px;
  width: 40px;
  height: 40px;
  -o-object-fit: contain;
  object-fit: contain;
}

.mine .wrapper .content .wallet {
  margin-top: 50px;
  padding: 0 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .wallet .part-1 {
  display: flex;
  align-items: center;
  height: 50px;
}

.mine .wrapper .content .wallet .font-primary-color {
  color: #000;
  font-size: 15px;
  margin: 20px 0 5px;
}

.font-gray {
  color: #868686;
}

.mine .wrapper .content .wallet .part-2 {
  display: flex;
  align-items: center;
  height: 80px;
}

.mine .wrapper .content .wallet .part-2 .balance {
  flex: 1;
  font-size: 28px;
  color: #7e5678;
  font-weight: 700;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  font-size: 18px;
  border-radius: 50%;
  color: #fff;
  background-color: #e6c3a1;
}
.cskh{
  position: absolute;
  width: 32px;
  height: 32px;
  top: 15px;
  right: 60px;
}
.cskh img{
  width: 100%;
  height: 100%;
}
.account-money{
    background: url('/img/mine/user_balance.png') no-repeat;
    background-size: cover;
    height: 110px;
    padding: 0 20px 30px;
    color: #fff;
    width: 90%;
    margin: 0 auto;
    margin-top: 19px;
    background-position: 50%;
    border-radius: 10px;
}
.account-money .accounts{
  font-size: 14px;
  color: hsla(0, 0%, 100%, .6);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.account-money .accounts-money {
    color: #f2d8be;
    font-size: 16px;
    font-weight: 700;
}
.account-money .deposit{
  position: relative;
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.money_zz {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 35px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAACOBAMAAACcWJCKAAAAHlBMVEVHcEwAAAAAAAAEBATu7u7f39+8vLz////8/Pz39/c8OwPaAAAACnRSTlMABAsSjmU9/9y2RVndewAABPVJREFUeAHs1YlR9TAMxHGpg6w7sNMB6b83YjnZ8WhsxH09/76TG/5vEfIfLMuyLMuyqMryzbG36vr7ay2bkau1wpHPt7TYXWPc9IbTGv/n0YqhW2oBvK1aw/8cLbnAE3gt+ScNf51zUdC0O2k//BX/46cl7k4bVvx32GYz990TzYYPXWfn9SufJu9DCxJSMRn5hAF1y4cs45UL5s270IAklNyw/TB+S87lr+nTVVwxw7q5qf8KUi6XdCon8ObMlm/w8PXVMPmsOdLVnJ0LhNl9+yg+tIF50OKCuWRK15wSJJXixUeH9Zn/Yca/RZecyTnzPEgsubD8qH3Bi/F5bRr84/wab9y0XGw4lIuU6snE8YP6zP+v+m+cuAbFmXzSfDfllCTX7PtxGsfPYXxSnp7bn+6vF2Hw+LBUqRo3t8zWWOxZx/3cp/sBmcZPBgjym+0C+kv7rmtBhD2i5NZ8r2za0q7MUd3tzUvxc1DfdL2VAPrNuQWvweIuuW9erpkzs3W3K0N8ZAbDd+8/IxsE+tpKip78DO343GFx3hUmcZiaye2J474zB8Oz/fzg+w8F5Ci/+wbgUx14X7trElc7lN0Pz1Oh6Wk5yMLvbe/HAF+9TOK7j4rU4DVYmtTDL8PgyR/dwcrd4fZqTillZ3iPb7ebMpL7/PD5Y+O5b1/sg8FZfLZyP3Mfnnuf6R49np1w/Blp0j/GLj8t++Dz4lx5kNzt/Qi5+EH9XC5AuuGjPmfHsXSz3nHwZ+7M4LZtGAyjncEbhNxABDyBfNBdvxcQzBWkDULfc6i2LeWqQh/qBH9/qpWsd1OMGMT7nukAmZUrKmfvl84HHf5BwKUPqD+zPGOA/YF/SdA3hRM/A+UaqnP7re+HD+mCCr5/NeO0/vkJ2H4DnuT5qQmVO17FSs7SpL7P3ieS0j3Tx3cuIKe3X/y+CTc4/RfTYPqBW+AZVY3rjcN5JntfuMO9Apxgxn3N24JbwAhkJcl4v8/Oc3Jf4y2N0/n3/gG9z91fYgBK+3jwTgGEPNkH6izM8p9Nj/VVvjNW45nYNu/9BL2TYbxcw9/CsynzxwjEFUHNxGnxKDxUMK7n1o5wDu8Al45JP54yBl+ncmwDVgtFwnG16L0zfLt+9p9xO8VP8Nh4tmau907uuPEN+nFy73a1QFWhbrNvEts5c5N3yhfILx4g48EWXX92nmAnCpXbvIMB8m2gL7zg/2DFngGDKLVN5bxZir3zD81rF9aAMghfteOe6GTvPpRzvrVN6sla3sEw1qXpgwoyivMDHlB1OVFqfn3qvZtJsL8qHr6KCPS8qnFGXu7dbP/40Pgm3mkf9/6R4D0O41t7R/zXeEDhTHxn3lG/HEF/FBa+W+9gyPln/93r9Z1958Dfe/Ii3tE/B9ixbvb9ut7BMC0gEmPYDzGK1A3rPpZ3kO6PD4HE2G0QdpSfaaeeHN87SGnMK9Qicv0nO8RJtNR1PY6JYR/Tu32HdB/zFB9ZVSsTt/ig656v0nXxwe0mE5f8e00zZst2zUf0/qMdOhYAAAAAGORvPYp9hRDevXvH+8O7d7x7x7t3vHvHu3e8e/eOd+94945373j3jnfvePfuHe/e8e4d797x7h3v3r3j3TvevePdO9694927d7x7x7t3vHvHu3e8e8e7d+94945373j3jnfvePfuHe/e8e4d797x7h3v3gnJSBQywpXfQwAAAABJRU5ErkJggg==');
    background-size: 100%;
}
.box-mine{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.box-mine .item-box-mine {
    flex-basis: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 16px 8px;
    background-color: #fff;
    border-right: 1px solid #ebedf0;
    border-bottom: 1px solid #ebedf0;
}
.box-mine .item-box-mine img {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin-bottom: 8px;
}
.box-mine .item-box-mine span {
    font-size: 12px;
    color: #646566;
    word-break: break-all;
}
.sign-out {
    margin: 20px auto;
    max-width: 400px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border: none;
    background: linear-gradient(90deg, #fe8f30, #ff3384);
    display: block;
}
</style>
